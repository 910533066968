// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

body{
  color: red !important;
}


// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}
.css-13rwezd {
    background-color: #95a3d1;
    width: calc(100% - 260px);
    min-height: calc(100vh - 88px);
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 21px;
    margin-top: 68px;
    margin-right: 20px;
    border-radius: 12px;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
    -webkit-transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    margin-left: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

.css-kvip5o-MuiToolbar-root {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 48px;
      padding-top: 6px;
      padding-bottom: 6px;
  }

  .css-1l1njq7 {
    background-color: #e3f2fd;
    width: calc(100% - 260px);
    min-height: calc(100vh - 88px);
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 20px;
    margin-top: 70px;
    margin-right: 20px;
    border-radius: 12px;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
    -webkit-transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    margin-left: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .css-4qtn0a-MuiDrawer-docked .MuiDrawer-paper {
    top: 59px;
  }

  .css-1lsvqp4-MuiTypography-root {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.334em;
    font-family: Roboto,sans-serif;
    color: inherit;
  }

  .css-1wbzuz8-MuiTypography-root {
    margin: 0;
      margin-top: 0px;
      margin-bottom: 0px;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.334em;
    font-family: Roboto,sans-serif;
    color: inherit;
    margin-top: auto;
    margin-bottom: auto;
  }

  .css-4knyec-MuiTypography-root {
    margin: 0;
      margin-top: 0px;
      margin-bottom: 0px;
    font-size: 0.8rem;
    color: #212121;
    font-weight: 500;
    font-family: Roboto,sans-serif;
    line-height: 1.334;
    color: inherit;
    margin-top: auto;
    margin-bottom: auto;
  }

  .css-a1gz8f-MuiTypography-root {
    margin: 0;
    font-size: 0.8rem;
    color: #212121;
    font-weight: 500;
    font-family: Roboto,sans-serif;
    line-height: 1.334;
    color: inherit;
  }

  .css-1l1njq7 {
    background-color: #e3f2fd;
    width: calc(100% - 260px);
    min-height: calc(100vh - 88px);
    flex-grow: 1;
    padding: 9px;
    margin-top: 70px;
    margin-right: 20px;
    border-radius: 12px;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    margin-left: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .css-17pmaar-MuiCardContent-root {
    padding: 16px;
    padding: 12px;
      padding-bottom: 12px;
  }

  .css-19jx8js-MuiPaper-root-MuiCard-root {
    background-color: #ffffff;
    color: #616161;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: none;
    background-image: none;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 9px;
    border: 1px solid;
      border-top-color: currentcolor;
      border-right-color: currentcolor;
      border-bottom-color: currentcolor;
      border-left-color: currentcolor;
    border-color: #90caf975;
    background: #ffffff;
  }

  .css-okt5j6-MuiDataGrid-columnHeaders {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 35px !important;
    max-height: 35px !important;
    line-height: 35px !important;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
.MuiDataGrid-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    //min-height: 35px !important;
    //max-height: 35px !important;
    //line-height: 35px !important;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    break-inside: avoid;
  }

  .MuiDataGrid-cell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    //min-height: 35px !important;
    //max-height: 35px !important;
    line-height: 35px !important;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .css-1pans1z-MuiDataGrid-virtualScroller {
    overflow: auto;
    overflow-x: auto;
    margin-top: 35px !important;
  }

  .css-ps5b8d-MuiGrid-root {
    -webkit-flex-basis: 91.666667%;
    -ms-flex-preferred-size: 91.666667%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }

  .css-rh0xq-MuiPaper-root-MuiAppBar-root {
    color: #616161;
    color: inherit;

  }
  .css-tz7fsy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 2.5rem !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}


::-webkit-scrollbar-thumb {
  background: #757575;
  border-radius: 5px;
}


::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

// hide manual date icon from date picker
.PrivateDatePickerToolbar-penIcon {
  display: none;
}

.bell{
  display:block;
  width: 30px;
  height: 30px;
  font-size: 40px;
 // margin:50px auto 0;
  color: #ffffff;
  background-color: #ff0000;
  -webkit-animation: ring 4s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s .7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }

  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}